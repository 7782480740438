import React from 'react';
import PropTypes from 'prop-types';

const IngredientInputs = ({ idx, ingredientState, handleIngredientChange }) => {
      const amountId = `amount-${idx}`;
      const unitsId = `units-${idx}`;
      const nameId = `name-${idx}`;
      return (
        <div className="ingredient-inputs" key={`ingredient-${idx}`}>
          <label htmlFor={amountId}>Amount</label>
          <input
            type="text"
            name={amountId}
            data-idx={idx}
            id={amountId}
            className="amount"
            value={ingredientState[idx].amount}
            onChange={handleIngredientChange}
          />
          <label htmlFor={unitsId}>Units</label>
          <input
            type="text"
            name={unitsId}
            data-idx={idx}
            id={unitsId}
            className="units"
            value={ingredientState[idx].units}
            onChange={handleIngredientChange}
          />
          <label htmlFor={nameId}>Name</label>
          <input
            type="text"
            name={nameId}
            data-idx={idx}
            id={nameId}
            className="name"
            value={ingredientState[idx].name}
            onChange={handleIngredientChange}
          />
        </div>
      );
  };

  IngredientInputs.propTypes = {
    idx: PropTypes.number,
    ingredientState: PropTypes.array,
    handleIngredientChange: PropTypes.func
  };

  export default IngredientInputs