import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// import recipes from '../assets/data';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../assets/back.svg';
// import { Form } from '../components/NewRecipeForm';
import Header from '../components/Header';

const RecipePage = () => {

  let {id: recipeId} = useParams();
  let navigate = useNavigate();

  // let recipe = recipes.find(recipe => recipe.id === Number(id))
  let [recipe, setRecipe] = useState(null)

  useEffect(() => {
    let getRecipe = async () => {
      if(recipeId === 'new') return;
      let response = await fetch(`/api/recipes/${recipeId}`,{
        method: 'GET'
      })
      let data = await response.json()
      setRecipe(data)
      console.log(data)
    }
    
    getRecipe()
  }, [recipeId])
  
  

  let handleSubmit = () => {
    if(recipeId !== 'new' && !recipe.body) {
      deleteRecipe()
    }
    else if(recipeId !== 'new') {
      updateRecipe()
    }
    // else if(recipeId === 'new' && recipe !== null) {
    //   createRecipe()
    // }
    navigate('/')
  }

  // let createRecipe = async () => {
  //   await fetch(`/api/recipes/`, {
  //     method: 'POST', 
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify( { ...recipe, 'updated':new Date(), ingredients: JSON.stringify({}), directions: JSON.stringify({}) }),
  //   })
  //   navigate('/')
  // }

  let updateRecipe = async () => {
    await fetch(`/api/recipes/${recipeId}/`, {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(recipe)
    })
  }

  let deleteRecipe = async () => {
    await fetch(`/api/recipes/${recipeId}/`, {
      method: 'DELETE', 
      headers: {
        'Content-Type': 'application/json'
      }
    })
    navigate('/')
  }

  let getTitle = (recipe) => {
    const title = recipe.title ? JSON.parse(recipe.title):"Untitled Recipe"
    return title
  }
  let getDate = (recipe) => {
    return new Date(recipe.updated).toLocaleDateString()
  }
  let getContent = (recipe) => {
    let content = JSON.parse(recipe.body.replaceAll('\n',' '))
    return content
  }

  return (
    <div className="wrapper">
      <Header title={recipe && getTitle(recipe)} /><div className="recipe">
        <div className="recipe-header">
          <h3>
            <Link className="back-button" to="/"><BackIcon onClick={handleSubmit} /></Link>
          </h3>
          <div className="recipe-header-content">
            <h3 className="recipe-meta">updated {recipe && getDate(recipe)}</h3>
            {recipeId !== 'new' ? (
              <button onClick={deleteRecipe}>Delete</button>
            ):
            (
              <button onClick={handleSubmit}>Done</button>
            )}
          </div>
          
        </div>
          <p className="recipe-description">{recipe && getContent(recipe)}</p>
          <h3 className="recipe-section-title">Ingredients</h3>
          <ul className="recipe-ingredients-list">
            {recipe && JSON.parse(recipe.ingredients).map(({amount, units, name}) => {
              return (
                <li key={'ing-'+name}>
                  <p>
                  <span>{amount}</span>&nbsp;
                  <span>{units}</span>&nbsp;
                  <span>{name}</span>
                  </p>
                </li>
              );
            })}
          </ul>
          <h3 className="recipe-section-title">Instructions</h3>
          <ol class="recipe-directions-list">
          {recipe && JSON.parse(recipe.directions).map(({content}, index) => {
              return (
                <li key={'dir-'+index}>
                  <span>{content}</span>
                </li>
              );
            })}
          </ol>
      </div>
    </div>
  )
}

export default RecipePage
