import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../assets/back.svg';
import NewRecipeForm from '../components/NewRecipeForm';
import Header from '../components/Header';

const RecipePage = () => {

  let {id: recipeId} = useParams();
  let navigate = useNavigate();

  // let recipe = recipes.find(recipe => recipe.id === Number(id))
  let [recipe, setRecipe] = useState(null)

  useEffect(() => {
    let getRecipe = async () => {
        return;
    //   if(recipeId === 'new') return;
    //   let response = await fetch(`/api/recipes/${recipeId}`,{
    //     method: 'GET'
    //   })
    //   let data = await response.json()
    //   setRecipe(data)
    }
    
    getRecipe()
  }, [recipeId])
  
  // let handleSubmit = () => {
  //     createRecipe()
  //     navigate('/')
  // }

  // let createRecipe = async () => {
  //   await fetch(`/api/recipes/`, {
  //     method: 'POST', 
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     title: JSON.stringify(),body: JSON.stringify( { ...recipe, 'updated':new Date(), ingredients: JSON.stringify({}), directions: JSON.stringify({}) }),
  //   })
  //   navigate('/')
  // }

  return (
    <div className="wrapper">
      <Header title="Create a Recipe" />
      <div className="recipe">
        <div className="recipe-header">
          <h3>
            <Link className="back-button" to="/"><BackIcon /></Link>
          </h3>
          {/* <button onClick={navigate('/')}>Cancel</button> */}
          {/* <button onClick={handleSubmit}>Done</button> */}
        </div>
          <NewRecipeForm recipe={recipe} />
      </div>
    </div>
  )
}

export default RecipePage
