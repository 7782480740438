import React, {useState} from 'react'; 
import { useNavigate } from 'react-router-dom';
import IngredientInputs from './IngredientInputs';
import DirectionInputs from './DirectionInputs';

const NewRecipeForm = () => {    
  let navigate = useNavigate();

  const [metaState, setMetaState] = useState({
    title: '',
    description: '',
  })
  const handleMetaChange = (e) => setMetaState({
    ...metaState,
    [e.target.name]: [e.target.value],
  })

  const blankIngredient = {amount: '', units:'', name:''};
  const [ingredientState, setIngredientState] = useState([
    {...blankIngredient}
  ]);
  const addIngredient = () => {
    setIngredientState([...ingredientState, {...blankIngredient}]);
  }; 
  const handleIngredientChange = (e) => {
    const updatedIngredients = [...ingredientState];
    updatedIngredients[e.target.dataset.idx][e.target.className] = e.target.value;
    setIngredientState(updatedIngredients);
  }

  const blankDirection = {content:''};
  const [directionState, setDirectionState] = useState([
     {...blankDirection}
  ]);
  const addDirection = () => {
    setDirectionState([...directionState, {...blankDirection}]);
  }; 
  const handleDirectionChange = (e) => {
    const updatedDirections = [...directionState];
    updatedDirections[e.target.dataset.idx][e.target.className] = e.target.value;
    setDirectionState(updatedDirections);
  }

  let [recipe, setRecipe] = useState(null);
  let createRecipe = async () => {
    await fetch(`/api/recipes/`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        updated:new Date(), 
        title:JSON.stringify(metaState.title), 
        body:JSON.stringify(metaState.description), 
        ingredients: JSON.stringify(ingredientState), 
        directions: JSON.stringify(directionState)
      }),
    });
    navigate('/');
  }
  let handleSubmit = () => {
      createRecipe()
    navigate('/')
  }

  return (        
    <form action={setRecipe}>         
      <label htmlFor="title">Title</label>   
      <input 
        type="text" 
        name="title" 
        id="title"
        value={metaState.title}
        onChange={handleMetaChange}
      /> 
      <label htmlFor="description">Description</label> 
      <textarea 
        name="description"
        id="description"
        value={metaState.description}
        onChange={handleMetaChange}
      ></textarea>
      <hr></hr>
      <h3>Ingredient List</h3>
      {
        ingredientState.map((val,idx) => (
          <IngredientInputs 
            key={`ingredient-${idx}`}
            idx={idx}
            ingredientState={ingredientState}
            handleIngredientChange={handleIngredientChange}
          />
        ))
      }    
      <input 
        type="button" 
        value="Add Ingredient +"
        onClick={addIngredient} 
      />  
      <hr></hr>
      <h3>Instructions List</h3>
      {
        directionState.map((val, idx) => (
          <DirectionInputs 
            key={`direction-${idx}`}
            idx={idx}
            directionState={directionState}
            handleDirectionChange={handleDirectionChange}
          />
        ))
      }      
      <input 
        type="button" 
        value="Add Instruction +" 
        onClick={addDirection}
        />      
      <button onClick={handleSubmit} type="submit" value="Submit" name="button">Submit</button>        
    </form>   
  );
}; export default NewRecipeForm;