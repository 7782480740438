import React from 'react';
import PropTypes from 'prop-types';

const DirectionInputs = ({ idx, directionState, handleDirectionChange }) => {
      const contentId = `content-${idx}`;
      return (
        <div className="direction-inputs" key={`direction-${idx}`}>
          <label htmlFor={contentId}>Instruction</label>
            <input
            type="text"
            name={contentId}
            data-idx={idx}
            id={contentId}
            className="content" 
            value={directionState[idx].content}
            onChange={handleDirectionChange}
            />
        </div>
      );
  };

  DirectionInputs.propTypes = {
    idx: PropTypes.number,
    DirectionState: PropTypes.array,
    handleDirectionChange: PropTypes.func
  };

  export default DirectionInputs;
